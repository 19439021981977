<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="row">
                                        <div class="col-6">
                                            <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                        <div class="col-6 text-right">
                                            <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateMPF()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                        </div>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex justify-content-end">
                                    <v-btn v-if="isApprove1 == true && item.approve1_by == ''" class="mr-3" color="success" elevation="2" small rounded @click="approve1MPF(item)">Approve</v-btn>
                                    <v-btn v-if="isApprove1 == true && item.approve1_by == ''" class="mr-3" color="red" elevation="2" small rounded @click="reject1MPF(item)" dark>Reject</v-btn>
                                    <v-btn class="" color="primary" elevation="2" small rounded @click="showItemDetails(item)" dark>Details</v-btn>
                                </div>
                            </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogDetailMpf" max-width="1024px" >
                <div class="card elevation-5">
                    <div class="card-header bg-transparent border-bottom-2 text-right">
                        <v-btn class="" color="red" elevation="2" small @click="dialogDetailMpf = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Detail MPF</h5>
                        <h5 class="card-title">Attachment : <v-chip color="blue" v-if="imageDetail" @click="showImage()" small dense dark> Click for view attachment </v-chip> </h5>
                        <h5 class="card-title">Remark : </h5>
                        <div class="row">
                            <div class="col-lg-12 col-12 d-flex">
                                <!-- <v-textarea outlined v-model="mpfDetailRemarkModel" disabled counter label="MPF Remark"></v-textarea> -->
                                <ul>
                                    <li v-for="item in mpfDetailsList" :key="item.seq_no">
                                        <span>{{ item.created_by }} : {{ item.message_body }}</span>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </v-dialog>

            <v-dialog v-model="dialogShowImage" max-width="768px" >
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-img :src = imgUrl ></v-img>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'MPF',
                disabled: false,
                href: '#',
                },
                {
                text: 'MPF Sent',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'FROM', value: 'fromStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'TO', value: 'toStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'SUBJECT', value: 'cat_desc', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable:false,
            dialogDetailMpf: false,
            mpfDetailsList: [],
            imageDetail: '',
            dialogShowImage: false,
            imgUrl: '',
            isApprove1: false,
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){
            
            this.populateMPF()

        },

        async populateMPF(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MPFSent/getData`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                this.itemLists = res.data.result

                if(res.data.isApprove == '1'){
                    this.isApprove1 = true
                }
                
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async showItemDetails(id){

            this.$store.dispatch('setOverlay', true)

            this.mpfDetailTrId = id.tr_id

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFSent/getDetails`, {

                id: id.tr_id ? id.tr_id : '',

            },
            { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                this.dialogDetailMpf = true
                this.mpfDetailsList = res.data.result
                this.imageDetail = res.data.image
                
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
           
        },

        approve1MPF(id){

            Swal.fire({
                text: 'Do you want Approve this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'Cancel'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFSent/approve1MPF`, {

                        id: id.tr_id ? id.tr_id : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        if(res.data.result == 'OK'){

                            this.dialogDetailMpf = false
                            this.populateMPF()

                        }
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        reject1MPF(id){

            Swal.fire({
                text: 'Do you want Reject this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'Cancel'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFSent/reject1MPF`, {

                        id: id.tr_id ? id.tr_id : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        if(res.data.result == 'OK'){

                            this.dialogDetailMpf = false
                            this.populateMPF()

                        }
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        showImage(){

            this.imgUrl = process.env.VUE_APP_URL + this.imageDetail
            this.dialogShowImage = true

        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }
 

</style>